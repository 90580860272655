import { css } from "@emotion/react";

export const ButtonDefault = css`
  color: #5286fe;

  &:hover {
    background-color: #58baf7;
    color: white;
  }
`;

export const ButtonPrimary = css``;

export const ButtonDanger = css``;

export const ButtonInline = css`;
  font: normal normal 12px/1.2 'Helvetica Neue', Arial, sans-serif;  

  padding: 0px 8px;
  min-width: 88px;
  border: none;
  font: inherit;
  color: #fff;
  border-radius: 4px;
  outline: none;
  text-decoration: none;
  cursor: default;
  font-weight: 400;
  background: #5286FE;
  // border: 1px solid #ccc;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.30), 0px 1px 1px rgba(0, 0, 0, .4);
  display: block;
  height: 50%;

  &:active {
    background: linear-gradient(#4faefc, #006bff);
  }

`;
